import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Img from "gatsby-image"
import Link from 'gatsby-link';
import LazyLoad from 'react-lazyload';
import SectionCard from './SectionCard';
import moment from 'moment';
const { getArrayAutores } = require('../utils/tools.js');

const useStyles = makeStyles(theme => ({
    card: {
      //marginBottom: "1rem",
      borderBottom:"1px solid black",
    },
    cardContent: {
      //marginLeft:"-1rem",
      //paddingBottom:'0rem !important',
      display:"flex",
      flexDirection:"column",
      textAlign:"left",
    },
    excerpt:{
      marginTop:"1rem !important",
      color:"black",
      fontSize:"1.1rem !important",
      lineHeight:"1.5rem !important",
      [theme.breakpoints.down('md')]: {
        fontSize:"1.0rem !important",
        lineHeight:"1.4rem !important",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.9rem !important",
        lineHeight:"1.3rem !important",
      },
      //textShadow: "1px 1px #000",
    },
    escribe:{
      marginTop:"0.8rem !important",
      color:"red",
      fontWeight:"bold !important",
      fontSize:"0.9rem !important",
      [theme.breakpoints.down('md')]: {
        fontSize:"0.8rem !important",
      },
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.7rem !important",
      },
      //textShadow: "1px 1px #000",
    },
    escribe_label:{
      color:"red",
      marginTop:"1rem",
    },
    tituloNota:{
      marginTop:"1rem",
      color:"black",
      fontFamily:"FuturaBold",
      textDecoration:"none",
      lineHeight:"2rem",
      fontSize:"1.7rem",
    },
  }));

export default function RevistaNotaCard(props) {

  const { node, imgFluidDefault } = props;
  //console.log("NOTA CARD def img:",imgFluidDefault);
  const classes = useStyles();
  const created_date = moment( moment.utc(node.frontmatter.date)).format('DD/MM/YYYY');
  const autores = getArrayAutores(node.frontmatter.author);

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <time className={classes.fecha} dateTime={node.frontmatter.date}>
            {created_date}
        </time>
        <Link className={classes.tituloNota} to={`/${node.frontmatter.seccion}/${node.fields.slug}`}>
          {node.frontmatter.title}
        </Link>
        <Typography className={classes.excerpt} component="p">
          {node.frontmatter.subhead ? node.frontmatter.subhead : node.excerpt}
        </Typography>
        <Typography className={classes.escribe_label} component="p">
              {autores.length > 1 ?'Escriben ':'Escribe '}
              {autores.map( (item,index) => {
                if(index === 0){
                  return (
                    <Link key={`author-${(Math.random()*100)+1}`} className={classes.escribe} to={`/autor/${item.slug}`}>
                      {item.autor}
                    </Link>
                  );
                }
                if((index+1) === autores.length){
                    return(<>
                      {` y `}
                      <Link key={`author-${(Math.random()*100)+1}`} className={classes.escribe} to={`/autor/${item.slug}`}>
                        {item.autor}
                      </Link>
                    </>);
                }
                return(
                  <>
                    {`, `}
                    <Link key={`author-${(Math.random()*100)+1}`} className={classes.escribe} to={`/autor/${item.slug}`}>
                      {item.autor}
                    </Link>
                  </>)
                })
              }
            </Typography>
      </CardContent>
    </Card>
  );
}

RevistaNotaCard.defaultProps = {
  node: {
    frontmatter: {},
    fields: {}
  }
}
