import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Typography, Grid, Icon, GridList, GridListTile, Input, Button } from "@material-ui/core";
import { navigate } from 'gatsby';
import withWidth from '@material-ui/core/withWidth';
import Layout from "../components/layout";
// import SEO from '../components/SEO'
import Nota from "../components/Nota";
import RevistaNotaCard from "../components/RevistaNotaCard";
import Link from 'gatsby-link';
import SeccionesHeader from '../components/SeccionesHeader';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SEOCategory from '../components/SEOCategory';

const useStyles = makeStyles(theme => ({
    boxContenedor:{
        position:'relative',
        textAlign:"center",
        margin:"2rem",
    },
    pageControls:{
      marginTop:"1rem",
      display:"flex",
      justifyContent:"center",
    },
    boxCenterControls:{
        width:"10rem",
        position:"relative",
    },  
    gridNotaItem:{
      marginBottom: '2rem',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '1rem',
      }
    },
    boxRevistaHeader: {
      backgroundColor:"#E5332A",
      marginTop:"2rem", 
      padding:"3rem 3.5rem", 
      display:"flex", 
      justifyContent: "space-between",
      [theme.breakpoints.down('sm')]: {
        padding:"1rem 1.5rem",
        marginTop:"1rem",
      },
    },
    boxRevistaListaLabel: {display:"flex",flexDirection:"column"},
    labelRevistaBoldBlack: {
      color:"black",
      fontSize:"4rem",
      fontFamily:"FuturaBold",
      lineHeight:"1.5rem",
      marginTop:"2.7rem",
      [theme.breakpoints.down('sm')]: {
        fontSize:"1.3rem",
        marginTop:"0.3rem",
      },
    },
    labelRevistaBoldWhite: {
      color:"white",
      fontSize:"4rem",
      fontFamily:"FuturaBold",
      lineHeight:"1.5rem",
      [theme.breakpoints.down('sm')]: {
        fontSize:"1.3rem",
      },
    },
    labelRevistaBoldWhiteLittle: {
      color:"white",
      fontFamily:"FuturaBold",
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.5rem",
      },
    },
    labelRevistaWhiteLittle: {
      color:"white",
      [theme.breakpoints.down('sm')]: {
        fontSize:"0.5rem",
      },
    },
    goButton:{
      marginLeft: '1rem',
      border: '1px solid black',
      padding: 0,
      minWidth: '2rem',
    },
}));

//TODO Hacerla un componente importable para evitar repeticion de codigo.
function RevistaHeader() {
  const classes = useStyles();
  return(
    <Box className={classes.boxRevistaHeader}>
      <Box className={classes.boxRevistaListaLabel}>
        <span className={classes.labelRevistaBoldWhite}>Política Obrera</span>
        <span className={classes.labelRevistaBoldBlack}>Revista</span>
      </Box>
      <Box className={classes.boxRevistaListaLabel} style={{alignSelf:"center"}}>
        <span className={classes.labelRevistaBoldWhiteLittle}>Revista teórica</span>
        <span style={{color:"white"}}>de Polìtica Obrera</span>
      </Box>
    </Box>
  );
};

function ListaNotas({notas,imgFluidDefault}) {
    const classes = useStyles();
    const isRevista = notas[0].frontmatter.seccion === "revista";
    // const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.down('sm'));

    if(!isRevista) {
      return(
        <Box className={classes.boxContenedor} >
          <Grid container spacing={2}>
          {
            notas.map(nota => (
              <Grid key={`seccion-${(Math.random()*100)+1}`} item xs={12} className={classes.gridNotaItem} >
                <Nota node={nota} imgFluidDefault={imgFluidDefault} flow="horizontal" />
              </Grid>
            ))
          }
          </Grid>
        </Box>
      );
    }else{
      return (
        <Box className={classes.boxContenedor} >
          <Grid container spacing={2}>
          {
            notas.map(nota => (
              <Grid key={`seccion-${(Math.random()*100)+1}`} item xs={12} md={4} className={classes.gridNotaItem} >
                <RevistaNotaCard node={nota} imgFluidDefault={imgFluidDefault} />
              </Grid>
            ))
          }
          </Grid>
        </Box>
        // <Box className={classes.boxContenedor} >
        //   <GridList cellHeight={400} className={classes.gridList} cols={matches ? 1 : 3}>
        //     {notas.map(nota => (
        //       <GridListTile key={nota.fields.slug} cols={1}>
        //         <RevistaNotaCard node={nota} imgFluidDefault={imgFluidDefault} />
        //       </GridListTile>
        //     ))}
        //   </GridList>
        // </Box>
      );
    }
}

function Paginado({currentPage, numPages, autorSlug}) {
  const [pageValue, setPageValue] = useState(currentPage)
  const classes = useStyles();

  const handleGoToPage = () => {
    navigate(`/${autorSlug === 'revista' ? '' : 'seccion/'}${autorSlug}/${pageValue > numPages ? numPages : pageValue > 1 ? pageValue : ''}`);
  }

  const handleGoClick = (e) => {
    handleGoToPage();
  }

  return(
    <Box className={classes.boxContenedor} >
      <Box className={classes.pageControls}>
        <Box className={classes.boxCenterControls}>
          {/* {currentPage > 1
            &&  (
              <Box className={classes.boxPrevPage}>
                <Link to={`/${autorSlug === 'revista' ? '' : 'seccion/'}${autorSlug}/${(currentPage - 1) > 1 ? currentPage - 1 : ''}`}>
                  <Icon>keyboard_arrow_left</Icon>
                </Link>
              </Box>
            )
          } */}
          <Typography className={classes.infoControls} component="span">
            <Input
              type="number" 
              style={{ width:'4rem' }}
              value={pageValue}
              inputProps={{
                min:1,
                max:numPages,
              }}
              onChange={e => setPageValue(e.target.value)}
            />
            <b>{`/${numPages}`}</b>
            <Button 
              onClick={handleGoClick}
              className={classes.goButton}
            >
              Ir
            </Button>
          </Typography>
          {/* {currentPage < numPages
            && (
              <Box className={classes.boxNextPage}>
                <Link to={`/${autorSlug === 'revista' ? '' : 'seccion/'}${autorSlug}/${currentPage + 1}`}>
                  <Icon>keyboard_arrow_right</Icon>
                </Link>
              </Box>
            )
          } */}
        </Box>
      </Box>
    </Box>
  );
}

function InfiniteScroll({items, currentPage, numPages, autorSlug, imgFluidDefault}) {
  // cargamos de a 6 elementos
  const [ hasMore, setMore ] = useState(items.length > 6)
  const [ currentList, setCurrentList ] = useState([...items.slice(0, 6)])
  const loadEdges = () => {
    const currentLength = currentList.length
    const more = currentLength < items.length
    const nextEdges = more ? items.slice(currentLength, currentLength + 6) : []
    setMore(more)
    setCurrentList([...currentList, ...nextEdges])
  }

  const handleScroll = () => {
    if ( !hasMore ) return;
    if ( window.innerHeight + document.documentElement.scrollTop >= (document.documentElement.offsetHeight - 5) ){
      loadEdges();
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  });

  return (
    <>
      <Paginado
        autorSlug={autorSlug}
        numPages={numPages}
        currentPage={currentPage}
      />
      <ListaNotas notas={currentList} imgFluidDefault={imgFluidDefault} />
      {
        !hasMore &&
          <Paginado
              autorSlug={autorSlug}
              numPages={numPages}
              currentPage={currentPage}
          />
      }
      {
        hasMore &&
          <div>Escrollea hacia abajo para cargar mas...</div>
      }
    </>
  )
}

function Seccion(props) {
  const classes = useStyles();
  const notas = props.data.allMarkdownRemark.edges.map(item => item.node);
  const imgFluidDefault = props.data.file.childImageSharp.fluid;
  const { seccion, seccionSlug, numPages, currentPage } = props.pageContext;
  return (
    <Layout>
      <SEOCategory category={seccion} slug={seccionSlug}/>
      {seccionSlug !== "revista" && <SeccionesHeader seccion={seccion} />}
      {seccionSlug === "revista" && <RevistaHeader />}
      {notas.length > 0 && (
          <InfiniteScroll
              items={notas}
              imgFluidDefault={imgFluidDefault}
              autorSlug={seccionSlug}
              numPages={numPages}
              currentPage={currentPage}
          />
        )
      }
      {notas.length === 0 && (
        <Box className={classes.boxContenedor} >
          <Typography component="p">
            <b>No hay notas por el momento en esta sección.</b>
          </Typography>
        </Box>
      )}
    </Layout>
  );
}


export const query = graphql`
    query($seccionSlug: String, $skip: Int, $limit: Int) {
        allMarkdownRemark(
            filter: {frontmatter: {seccion: {eq: $seccionSlug}}}
            sort: { fields: [frontmatter___date], order: DESC }
            limit: $limit
            skip: $skip
        ){
            edges{
                node{
                    frontmatter {
                        title
                        image
                        date
                        author
                        seccion
                        subhead
                    }
                    fields {
                        slug
                    }
                }
            }
        }
        file(sourceInstanceName: {eq: "defaults"}) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;

export default withWidth()(Seccion);
