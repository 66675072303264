import React from 'react';
import { Box, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { graphql, useStaticQuery } from 'gatsby';

import BannerPolitica from './../assets/sections/politicas.jpg';
const { slug } = require('../utils/tools.js');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: 200,
    [theme.breakpoints.down('sm')]: {
      height: 120
    }
  },
  wrapper: {
    position: 'relative',
    height: 200,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 120
    }
  },
  label: {
    position: "absolute",
    bottom: "0"
  },

  sectionWrap: {
    top:"1.5rem",
    right:"1.5rem",
    padding:"0.2rem 0.8rem",
    // position: absolute,
    backgroundColor: theme.palette.background.default,
    display:"inline-block",
    textDecoration: "none"
  },
  sectionLabel: {
    color:"black",
    fontSize: "1.5rem",
    fontFamily: "FuturaBold",
    textDecoration:"none",
    textTransform: "uppercase",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1.2rem"
    }
  },
  sectionIntro: {
    backgroundColor: theme.palette.base,
    color: "#ffffff",
    fontSize: ".9rem",
    fontFamily: "FuturaBold",
    display: "table",
    padding: ".2rem .4rem",
    marginBottom: ".2rem",
    textTransform: "uppercase",
    [theme.breakpoints.down('sm')]: {
      padding: ".1rem .3rem",
      fontSize: ".8rem"
    }
  },
  economia: {
    backgroundColor: theme.palette.economia,
    color: "white"
  },
  politicas: {
    backgroundColor: theme.palette.politicas,
    color: "white"
  },
  internacionales: {
    backgroundColor: theme.palette.internacionales,
    color: "white"
  },
  'movimiento-obrero': {
    backgroundColor: theme.palette.movimiento,
    color: "white"
  },
  mujer: {
    backgroundColor: theme.palette.mujer,
    color: "white"
  },
  'sociedad-y-cultura': {
    backgroundColor: theme.palette.sociedad,
    color: "white"
  },
  'historia-y-teoria': {
    backgroundColor: theme.palette.historia,
    color: "white"
  },
  archivo: {
    backgroundColor: theme.palette.archivo,
    color: "black"
  },  
  juventud: {
    backgroundColor: theme.palette.juventud,
    color: "white"
  },
  'correo-de-lectores': {
    backgroundColor: theme.palette.correo,
    color: "white"
  },
  debate: {
    backgroundColor: theme.palette.debate,
    color: "white"
  },
  'leon-trotsky-80-anos': {
    backgroundColor: 'black',
    color: "white",
  },
}));

const SeccionesHeader = ({ seccion }) => {
  const classes = useStyles();
  const alias = slug(seccion);
  const seccionClass = classes[alias];
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {sourceInstanceName: {eq: "seccion"}}) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
    }
  `);
  const banner = data.allFile.edges.find(({ node }) => node.name === alias);
  return(
    <Box className={classes.root} style={{ backgroundImage: `url(${banner ? banner.node.publicURL:''})` }}>
      <Container maxWidth="xl">
        <Grid item xs={12} className={classes.wrapper}>
          <div className={classes.label}>
            <Box className={`${classes.sectionIntro} ${seccionClass}`}>
              {'Sección'}
            </Box>
            <Box className={classes.sectionWrap}>
              <h1 className={classes.sectionLabel}>
                {seccion}
              </h1>
            </Box>
          </div>
        </Grid>
      </Container>
    </Box>
  );
};

export default SeccionesHeader;
